import { Grid, IconButton } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form';
import propTypes from 'prop-types';
import { FaTimes } from 'react-icons/fa';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { toast } from 'react-toastify';

import AutoComplete from '../../componentes/AutoComplete';
import Button from '../../componentes/Button';
import CustomSelect from '../../componentes/Select';
import TextField from '../../componentes/TextField';
import Typography from '../../componentes/Typography';
import Modal from '../../componentes/Modal';

import styles from './styles';
import tiposCuenta from '../../constantes/tiposCuenta';
import {
  CAMPO_REQUERIDO, DEVOLUCION_MAYOR_IMPORTE, MAX_LONGITUD,
  SALDO_CUENTA_EXCEDIDO,
} from '../../constantes/mensajes';
import endpoints from '../../configuraciones/endpoints';
import axiosInstance from '../../configuraciones/axios';
import { formatoMoneda } from '../../utilidades/functions';

const schema = yup.object({
  tipoCuentaId: yup.string().required(CAMPO_REQUERIDO),
  cuentaId: yup.string().required(CAMPO_REQUERIDO),
  importe: yup.string().required(CAMPO_REQUERIDO)
    .test('length', MAX_LONGITUD(11), (val) => val?.length <= 11),
  motivoCancelacion: yup.string().required(CAMPO_REQUERIDO).max(255, MAX_LONGITUD(255)),
});

const CancelacionVenta = ({
  titulo, abierto, alCerrar, alEnviar,
  venta,
}) => {
  const classes = styles();
  const [cuentas, setCuentas] = useState();

  const metodos = useForm({
    resolver: yupResolver(schema),
  });
  const {
    formState: { errors },
    handleSubmit,
    reset,
    watch,
  } = metodos;

  const tipoCuentaId = watch('tipoCuentaId');

  /** Función que obtiene las cuentas con base al tipo de cuenta seleccionada. */
  const obtenerCuentas = useCallback(async () => {
    if (tipoCuentaId && venta?.lote?.manzana?.proyecto) {

      const cuentasBd = await axiosInstance.get(endpoints.cuentasPorTipoCuenta(tipoCuentaId), {
        params: {
          proyectoId: venta?.lote?.manzana?.proyecto.id,
        }
      });
      const cuentasListado = [];
      cuentasBd.forEach((cuenta) => {
        cuentasListado.push({
          id: cuenta.id,
          nombre: `${cuenta.nombre} - ${formatoMoneda(cuenta.saldo)}`,
          saldo: cuenta.saldo,
        })
      });
      setCuentas(cuentasListado);
    }
  }, [tipoCuentaId, venta]);

  /** Función que se ejecuta al cerrar el modal. */
  const manejarCerrar = () => {
    alCerrar();
    reset();
  }

  /** Método que valida que el importe a devolver sea posible de devolver. */
  const validarImporte = (cancelacion) => {
    const { cuentaId, importe } = cancelacion;
    const cuenta = cuentas.find(({ id }) => id === cuentaId);
    if (+importe > +cuenta.saldo) toast.warning(SALDO_CUENTA_EXCEDIDO);
    else if (+importe > +venta.total) toast.warning(DEVOLUCION_MAYOR_IMPORTE);
    else alEnviar(cancelacion, reset);
  }

  /** Effect para que obtener las cuentas */
  useEffect(() => obtenerCuentas(), [obtenerCuentas]);

  return (
    <Modal
      open={abierto}
      handleClose={manejarCerrar}
      maxWidth="lg"
      showTitle={false}
    >
      <Grid container style={{ width: '50vw' }}>
        <Grid item xs={12}>
          <FormProvider {...metodos}>
            <form id="frmCancelacion" onSubmit={handleSubmit(validarImporte)} noValidate>
              <Grid container alignItems="center" justifyContent="space-between">
                <Grid item xs>
                  <Typography variant="h6">{titulo}</Typography>
                </Grid>
                <Grid item xs="auto">
                  <IconButton onClick={manejarCerrar}>
                    <FaTimes size={15} />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <CustomSelect
                    name="tipoCuentaId"
                    label="Formas de pago:"
                    placeHolder="Selecciona una opción"
                    options={tiposCuenta}
                    className={classes.control}
                    error={errors.tipoCuentaId}
                    helperText={errors.tipoCuentaId?.message}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <AutoComplete
                    name="cuentaId"
                    label="Cuenta:"
                    placeHolder="Selecciona una opción"
                    options={cuentas}
                    className={classes.control}
                    error={errors.cuentaId}
                    helperText={errors.cuentaId?.message}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    name="importe"
                    type="coin"
                    label="Importe a devolver"
                    placeHolder="$0.00"
                    error={errors.importe}
                    helperText={errors.importe?.message}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="motivoCancelacion"
                    type="textarea"
                    rows={5}
                    inputProps={{ maxLength: 255 }}
                    label="Motivo de cancelación"
                    placeHolder="Capturar el motivo de cancelación"
                    error={errors.motivoCancelacion}
                    helperText={errors.motivoCancelacion?.message}
                    required
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} style={{ marginBlock: 20 }}>
                  <Grid container justifyContent="flex-end">
                    <Grid item>
                      <Button
                        variant="outlined"
                        label="Aceptar"
                        isSubmit
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </Grid>
      </Grid>
    </Modal>
  )
}

CancelacionVenta.propTypes = {
  /** Título del modal. */
  titulo: propTypes.string,
  /** Propiedad para validar si se muestra o no el modal. */
  abierto: propTypes.bool,
  /** Función para cerrar el modal. */
  alCerrar: propTypes.func,
  /** Función para enviar el formulario. */
  alEnviar: propTypes.func,
};

CancelacionVenta.defaultProps = {
  titulo: 'Cancelación de venta',
  abierto: false,
  alCerrar: () => { },
  alEnviar: () => { },
};

export default CancelacionVenta;
