/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { FormProvider, useForm } from 'react-hook-form';
import { Grid } from '@material-ui/core';

import Default from '../../contenedores/Default';
import TextField from '../../componentes/TextField';
import BarraProgreso from '../../componentes/BarraProgreso';
import axios from '../../configuraciones/axios';
import endpoints from '../../configuraciones/endpoints';
import {
  ELEMENTO_NO_ENCONTRADO,
} from '../../constantes/mensajes';
import styles from '../Venta/styles';
import Documentos from './documentos';


const Expediente = ({ history, match }) => {

  const { params: { id } } = match;
  const classes = styles();
  const methods = useForm();
  const { reset } = methods;
  const [datosVenta, setDatosVenta] = useState();

  /**
   * Método que regresa al listado de ventas.
   */
  const regresar = () => {
    history.push({
      pathname: '/ventas/expedientes',
    });
  };


  /**
   * Método que consulta los datos inciales de la pantalla
   * (vendedores y la venta por id).
   * @returns Promesa de resultado.
   */
  const consultarDatosIniciales = () => new Promise((resolve, reject) => {
    if (id) {
      const promesas = [
        axios.get(endpoints.obtenerDatosVentaExpediente(id))
      ];

      Promise.all(promesas).then(async (resultadosPromesas) => {
        const [ventaBd] = resultadosPromesas;
        if (ventaBd?.id) {
          reset({
            ...ventaBd,
            empresa: ventaBd.lote?.manzana?.proyecto?.empresa?.nombre,
            proyecto: ventaBd.lote?.manzana?.proyecto?.nombre,
            vendedor: ventaBd.vendedor?.nombre,
            manzana: ventaBd.lote?.manzana?.nombre,
            lote: ventaBd.lote?.numero,
            cliente: ventaBd.cliente?.nombre,
          })
          setDatosVenta(ventaBd);
        } else if (id) {
          toast.warning(ELEMENTO_NO_ENCONTRADO);
          regresar();
        }
        resolve();
      }).catch(reject);
    } else {
      regresar();
    }
  });

  /**
   * Evento tipo constructor al entrar a la pantalla de venta.
   */
  useEffect(() => {
    consultarDatosIniciales();
  }, [id]);

  return (

    <Default
      titulo="Expediente"
      placeHolder=""
      soloListado
      soloCancelar
      labelCancelar="Regresar al listado"
      frmId="frmVenta"
      cancelar={regresar}
    >
      <FormProvider {...methods} >
        <form noValidate id="frmLote" className={classes.expedienteForm}>
          <Grid container spacing={2} sx={12}>

            <Grid container spacing={2} sx={12} >
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <TextField
                  name="fecha"
                  type="date"
                  label="Fecha:"
                  disabled
                />
              </Grid>
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <TextField
                  name="empresa"
                  label="Empresa"
                  className={classes.control}
                  disabled
                />
              </Grid>
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <TextField
                  name="proyecto"
                  label="Proyecto:"
                  disabled
                />
              </Grid>
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <TextField
                  name="mesesFinanciamiento"
                  label="Plazos:"
                  disabled
                />
              </Grid>

              <Grid item lg={3} md={4} sm={6} xs={12}>
                <TextField
                  name="cliente"
                  label="Cliente:"
                  disabled
                />
              </Grid>
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <TextField
                  name="vendedor"
                  label="Vendedor:"
                  disabled
                />
              </Grid>
              <Grid item lg={3} md={4} sm={6} xs={2}>
                <Grid container spacing={2}>
                  <Grid item lg={6} md={6} sm={6} xs={2}>
                    <TextField
                      name="manzana"
                      label="Manzana:"
                      disabled
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={2}>
                    <TextField
                      name="lote"
                      label="Lote:"
                      disabled
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg={3} md={4} sm={6} xs={2}>
                <Grid container spacing={2}>

                  <Grid item lg={6} md={4} sm={6} xs={2}>
                    <TextField
                      name="total"
                      label="Total:"
                      type="coin"
                      disabled
                    />
                  </Grid>
                  <Grid item lg={6} md={4} sm={6} xs={2}>
                    <TextField
                      name="pagado"
                      label="Pagado:"
                      type="coin"
                      disabled
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={12}>
            <Grid item xs={12} className={classes.barraProgreso}>
              <BarraProgreso
                total={datosVenta?.mesesFinanciamiento || 1}
                avance={datosVenta?.mesesFinanciamiento ? datosVenta?.cobranzasPagadas : 1}
                esPorcentual={false} customDescripcion={'Pagos'} />
            </Grid>
          </Grid>
        </form>
      </FormProvider>
      <Documentos id={id} />
    </Default >
  );
};

export default React.memo(Expediente);